<template>
        <section class="content pl-2 pr-2">
            <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" ref="mainDataTable" v-if="showList"></CustomDataTable>
            <account v-if="!showList" :save-handler="saveHandler" :id="singleId"></account>

            <ModalOpeningBalance :title="openingBalancesModal.title" size="modal-lg" :showModal="openingBalancesModal.showModal" :hideModal="hideOpeningBalancesModal" :entity_type="openingBalancesModal.entity_type" :entity_id="openingBalancesModal.entity_id" v-if="openingBalancesModal.showModal"></ModalOpeningBalance>

            <ModalGeneral :title="ledgerModal.title" size="modal-lg" :showModal="ledgerModal.showModal" :hideModal="hideLedgerModal" v-if="ledgerModal.showModal">
                <div slot="modal-body">
                    <report-entity-ledger type="account" v-bind:report-name="ledgerModal.title" v-bind:entity_id="ledgerModal.account_id" :generate-on-load="true" :disable-entity-selection="true"></report-entity-ledger>
                </div>
            </ModalGeneral>
        </section>
</template>

<script>
    import Vue from 'vue';
    import CustomDataTable from "../table/CustomDataTable";
    import ModalOpeningBalance from "../openingBalance/ModalOpeningBalance";
    import {getPermissionByKey} from "@/helpers/userHelper";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {AccountService} from "@/services/account-service";
    import Account from "@/components/app/account/Account";
    import ReportEntityLedger from "@/components/app/report/ReportEntityLedger";
    import ModalGeneral from "@/components/app/dialog/ModalGeneral";

    let accountService = new AccountService();
    export default {
        name: 'Accounts',
        data: function(){
            return {
                pageName: 'Accounts',
                segments: [
                    { link: false, text: 'Accounts' },
                ],
                account: {},
                tableDataUrl: this.$globalSettings.api.endpoints.account.accountsDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                    {label: 'Type', name: 'ca_three_name', orderable: true},
                    {label: 'Name', name: 'name', orderable: true},
                    {label: 'Image', name: 'image', orderable: false, transform: ({data, name}) => data[name]? `<img src="${this.$apiBaseURL + '/' + data[name]}" class="img-sm" />`: '' },
                    {
                        label: '',
                        style: 'width: 10px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: [
                                {
                                    label: 'Edit',
                                    showLabel: true,
                                    iconClass: 'fas fa-edit',
                                    event: "click",
                                    handler: this.edit,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.account.account_edit}],
                                    conditions: [],
                                },
                                {
                                    label: 'Opening Balances',
                                    showLabel: true,
                                    iconClass: 'fas fa-box-open',
                                    event: "click",
                                    handler: this.showOpeningBalancesModal,
                                    dataPermissionConditions: [],
                                    conditions: [this.$globalSettings.permissions.account.account_edit],
                                },
                                {
                                    label: 'Ledger',
                                    showLabel: true,
                                    iconClass: 'fas fa-chart-line',
                                    event: "click",
                                    handler: this.showLedgerModal,
                                    dataPermissionConditions: [],
                                    conditions: [this.$globalSettings.permissions.report.report_account_ledger],
                                },
                                {
                                    label: 'Delete',
                                    showLabel: true,
                                    iconClass: 'fas fa-trash',
                                    event: "click",
                                    handler: this.delete,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.account.account_delete}],
                                    conditions: [],
                                },
                            ]
                        }
                    },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc'
                },
                additionalButtons: [],

                showList: false,
                singleId: 0,

                openingBalancesModal: {
                    title: '',
                    entity_type: null,
                    entity_id: null,
                    showModal: false,
                },
                ledgerModal: {
                    title: 'Account Ledger',
                    showModal: false,
                    account_id: null,
                }
            }
        },
        props: {
            mode:{
                type: String,
                default: () => 'list'
            },
            showAddButtons:{
                type: Boolean,
                default: () => true
            },
        },
        components: {
            ModalGeneral,
            ReportEntityLedger,
            Account, ModalOpeningBalance, CustomDataTable
        },
        computed: {

        },
        methods: {
            add(){
                this.showList = false;
                this.singleId = 0;
            },
            edit(data){
                this.showList = false;
                this.singleId = data.id;
            },
            saveHandler: async function () {
                this.showList = true;
                this.singleId = 0;
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this account?")) {
                    let loader = this.$loading.show();
                    let response = await accountService.delete(data.id);
                    if (response.isSuccess) {
                        await this.$refs.mainDataTable.loadTable();
                    }else{
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            },
            showOpeningBalancesModal(data){
                this.openingBalancesModal.title = data.name + ' Opening Balance';
                this.openingBalancesModal.entity_type = this.$globalEnums.obEntityTypes.Account;
                this.openingBalancesModal.entity_id = data.id;
                this.openingBalancesModal.showModal = true;
            },
            hideOpeningBalancesModal(){
                this.openingBalancesModal.showModal = false;
            },

            showLedgerModal(data){
                this.ledgerModal.showModal = true;
                this.ledgerModal.account_id = data.id;
            },
            hideLedgerModal(){
                this.ledgerModal.showModal = false;
            },

            async goToChartOfAccounts() {
                await this.$router.push({name: 'App.ChartOfAccounts'});
            },
        },
        mounted() {
            this.showList = this.mode === 'list';
            if(this.showAddButtons) {
                if (getPermissionByKey(this.$globalSettings.permissions.account.account_create)) {
                    this.additionalButtons.push({
                        label: 'Add',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-plus',
                        click: this.add
                    });
                }
                if (getPermissionByKey(this.$globalSettings.permissions.account.account_list)) {
                    this.additionalButtons.push({
                        label: 'Chart of Accounts',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-book',
                        click: this.goToChartOfAccounts
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
