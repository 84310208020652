<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <accounts></accounts>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Accounts from "@/components/app/account/Accounts";

    export default {
        name: 'Accounts_view',
        data: function(){
            return {
                pageName: 'Account Heads',
                segments: [
                    { link: false, text: 'Account Heads' },
                ],
                id: 0
            }
        },
        props: {},
        components: {
            Accounts,
            BreadCrumb
        },
        computed: {

        },
        methods:{

        },
        created() {

        }
    }
</script>

<style scoped>

</style>
